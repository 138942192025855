import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { InputText } from '../../../../components/Form';
import Breadcrumb from '../../../../shared/Breadcrumb';
import Loading from '../../../../shared/Loading';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import ChangemanLayout from '../../components/Layout';
import TextContent from '../../entities/TextContent';
import { useChangemanStore } from '../../stores/RootStore';

interface IIndexable {
  [key: string]: any;
}

const MainChangemanPage = () => {
  const renderPath = () => <Breadcrumb items={[{ label: 'Início', value: '/changeman' }]} />;
  const { loadind_current, current, saving, errors, add, getByName, removeItem } =
    useChangemanStore('textState');
  const [entity, setEntity] = useState<TextContent>(new TextContent());
  const { setMessage } = useStore('uiState');

  useEffect(() => {
    if (errors && errors.length > 0) {
      setMessage('error', JSON.stringify(errors));
    }
  }, [errors, setMessage]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleSend = async () => {
    if (!entity.id || saving) {
      return;
    }

    const result = await add(entity);
    if (result) {
      setMessage('success', result);
      console.log('handleSend:', result);
    }
  };

  const handleReceive = async () => {
    if (!entity.name || saving) {
      return;
    }

    await getByName(entity.name);
  };

  const handleRemove = async () => {
    if (!entity?.name) return;

    if (window.confirm(`Excluir conteúdo retornado? ${entity.name}`)) {
      const result = await removeItem(entity.name);

      if (result) {
        setMessage('success', result);
        console.log('handleRemove:', result);
      }
    }
  };

  const renderTextArea = (field: string, label: string, className?: string) => (
    <div className={className}>
      <label htmlFor={field} className="form-label">
        {label}
      </label>
      <textarea
        className="form-control mb-3"
        id={field}
        name={field}
        rows={5}
        value={(entity as IIndexable)[field]}
        onChange={handleInputChange}
      />
    </div>
  );

  const downloadContent = () => {
    if (current?.content) {
      var a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + current.content;
      a.download = current.name;
      a.click();
    }
  };

  return (
    <ChangemanLayout breadcrumb={renderPath}>
      <PageHeader
        title="Dashboard"
        actions={[
          { title: 'Excluir', onClick: handleRemove },
          { title: 'Receber', onClick: handleReceive },
          { title: 'Enviar', onClick: handleSend },
        ]}
      />
      <Loading active={loadind_current || saving} />
      <div className="row">
        <InputText
          autoComplete="off"
          required={true}
          label="Nome"
          name="name"
          value={entity.name}
          className="col-12"
          onChange={handleInputChange}
        />
      </div>

      {renderTextArea('content', 'Conteúdo')}

      <hr className="mt-4 mb-4" />

      <div>
        <label htmlFor="current" className="form-label">
          Conteúdo retornado
        </label>
        <textarea
          className="form-control mb-3"
          id="current"
          name="current"
          rows={8}
          value={current?.content}
          readOnly
        />
      </div>

      <div className="text-end">
        <button className="btn btn-dark" type="button" onClick={downloadContent}>
          Salvar no PC
        </button>
      </div>
    </ChangemanLayout>
  );
};

export default observer(MainChangemanPage);
