class User {
  id: string | undefined;
  name: string;
  email: string;
  telephone: string | undefined;
  defaultCompanyId: string;

  firebaseUids: string[];

  createdAt!: number;
  updatedAt!: number;

  constructor() {
    this.name = '';
    this.email = '';
    this.telephone = '';
    this.defaultCompanyId = '';
    this.firebaseUids = [];
  }
}

export default User;
