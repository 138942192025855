import { Link } from 'react-router-dom';

interface IProps {
  linkTo?: string;
  classList?: any[];
  k: number;
  value: string;
}

const TableField: React.FC<IProps> = ({ linkTo, classList, k, value }: IProps) => (
  <td key={k}>
    {linkTo ? (
      <Link className={`text-dark d-block ${classList ? classList[k] : ''}`} to={linkTo}>
        {value}
      </Link>
    ) : (
      <div className={`text-dark d-block ${classList ? classList[k] : ''}`}>{value}</div>
    )}
  </td>
);

export default TableField;
