import { __NEW__ } from '../../../constants';

class Timesheet {
  id: string | undefined;
  name: string;
  date: number;

  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;

  clientName: string;
  project: string;
  type: string;
  description: string;
  document: string;

  ownerId: string;
  companyId: string;

  createdAt!: number;
  updatedAt!: number;

  constructor(ownerId: string) {
    this.id = __NEW__;
    this.name = 'Angelito Casagrande';
    this.date = Date.now();

    this.startHour = new Date().getHours();
    this.startMinute = new Date().getMinutes();
    this.endHour = 0;
    this.endMinute = 0;

    this.clientName = '';
    this.project = '';
    this.type = '';
    this.description = '';
    this.document = '';

    this.ownerId = ownerId;
    this.companyId = '';
  }
}

export default Timesheet;
