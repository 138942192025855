import { createContext, useContext } from 'react';

import { TimesheetStore } from './TimesheetStore';

export const stores = Object.freeze({
  timesheetState: new TimesheetStore(),
});

export const storesContext = createContext(stores);
export const StoresProvider = storesContext.Provider;

export const useProjectStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
  useContext(storesContext)[store];
