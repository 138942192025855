class Company {
  id: string | undefined;
  logo: string;
  name: string;
  email: string;
  site: string;
  address: string;
  telephone: string;
  docto: string;

  ownerId: string;

  createdAt!: number;
  updatedAt!: number;

  constructor(ownerId: string) {
    this.logo = '';
    this.name = '';
    this.email = '';
    this.site = '';
    this.address = '';
    this.telephone = '';
    this.docto = '';

    this.ownerId = ownerId;
  }
}

export default Company;
