interface IProps {
  columns: string[];
  classList?: any[];
}

const TableHeader: React.FC<IProps> = ({ classList, columns }: IProps) => {
  return (
    <thead className="table-dark">
      <tr>
        {columns.map((c, k) => (
          <th key={k} className={`${classList ? classList[k] : ''}`}>
            {c}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
