import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../shared/Breadcrumb';
import Layout from '../../shared/Layout';

const HomePage: React.FC = () => {
  const history = useHistory();

  const renderPath = () => <Breadcrumb items={[{ label: 'Início', value: '/' }]} />;

  const handleHealthIndex = () => history.push('/health');
  const handleHelpDeskIndex = () => history.push('/helpdesk');
  const handleProjectIndex = () => history.push('/project');
  const handleChangemanIndex = () => history.push('/changeman');

  return (
    <Layout breadcrumb={renderPath}>
      <div className="row gy-3">
        <div className="col-lg-4">
          <div
            className="bg-secondary text-light p-5 rounded"
            role="button"
            onClick={handleHealthIndex}>
            <h1 className="display-4">Clínica</h1>
            <hr />
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="bg-secondary text-light p-5 rounded"
            role="button"
            onClick={handleProjectIndex}>
            <h1 className="display-4">Projetos</h1>
            <hr />
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="bg-secondary text-light p-5 rounded"
            role="button"
            onClick={handleHelpDeskIndex}>
            <h1 className="display-4">HelpDesk</h1>
            <hr />
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="bg-secondary text-light p-5 rounded"
            role="button"
            onClick={handleChangemanIndex}>
            <h1 className="display-4">Changeman</h1>
            <hr />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
