import { createContext, useContext } from 'react';

import { TextStore } from './TextStore';

export const stores = Object.freeze({
  textState: new TextStore(),
});

export const storesContext = createContext(stores);
export const StoresProvider = storesContext.Provider;

export const useChangemanStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
  useContext(storesContext)[store];
