import { Link } from 'react-router-dom';

const ProjectNavbar = () => {
  return (
    <>
      <li className="nav-item">
        <Link className="nav-link active" aria-current="page" to="/project">
          Início
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" aria-current="page" to="/project/timesheet">
          Apontamento de horas
        </Link>
      </li>
    </>
  );
};

export default ProjectNavbar;
