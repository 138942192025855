import '../../assets/css/main.scss';

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import { IStateType } from '../../router';
import { useStore } from '../../stores/RootStore';

const LoginPage: React.FC = () => {
  const location = useLocation<IStateType>();
  const { authenticated, error, login } = useStore('authState');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'emailInput') setEmail(e.target.value);
    if (e.target.name === 'passwordInput') setPassword(e.target.value);
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  // const handleCreateAccount = () => {
  //   history.push('accounts/create');
  // };

  return authenticated ? (
    <Redirect
      to={{
        pathname: location.state?.from?.pathname ?? '/',
        state: { from: '/login' },
      }}
    />
  ) : (
    <div className="login__container">
      <div className="login__left">
        <div className="login__form-box">
          <img src={logo} alt="Be Hold" />

          <form method="post" onSubmit={handleLogin}>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                name="emailInput"
                placeholder="name@example.com"
                value={email}
                onChange={handleChange}
                autoComplete="username"
              />
              <label htmlFor="emailInput">E-mail</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                name="passwordInput"
                placeholder="Password"
                value={password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              <label htmlFor="passwordInput">Senha</label>
            </div>

            <div className="d-grid gap-2 mb-3">
              <button className="btn btn-secondary" type="submit">
                Entrar
              </button>
              {/* <button type="button" className="btn btn-link" onClick={handleCreateAccount}>
                Criar conta
              </button> */}
            </div>

            {errorMessage ? (
              <div className="alert alert-danger" role="alert" style={{ maxWidth: '330px' }}>
                {errorMessage}
              </div>
            ) : undefined}
          </form>
        </div>
      </div>
      <div className="login__right"></div>
    </div>
  );
};

export default observer(LoginPage);
