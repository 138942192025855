import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { Table, TableBody, TableHeader, TableLine } from '../../../../components/Table';
import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import HealthLayout from '../../components/Layout';
import { useHealthStore } from '../../stores/RootStore';

const CompaniesPage = () => {
  const { loading_items, items, getItemsByOwner, removeItem } = useHealthStore('companyState');
  const { setMessage } = useStore('uiState');

  useEffect(() => getItemsByOwner(''), [getItemsByOwner]);

  const handleRemove = (id: string) => {
    removeItem('', id).then(({ type, message }) => setMessage(type, message));
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/health' },
        { label: 'Minhas clínicas', value: '/health/companies' },
      ]}
    />
  );

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader
        title="Minhas clínicas"
        actions={[{ title: 'Nova', url: '/health/companies/new' }]}
      />
      <Loading active={loading_items} />

      <HideConditional condition={loading_items}>
        <Table>
          <TableHeader columns={['Nome', '']} />
          <TableBody>
            {items?.map((it, i) => (
              <TableLine
                key={i}
                id={it.id}
                linkTo={`/health/companies/${it.id}`}
                classList={[null, null, 'text-center', 'text-end', null]}
                values={[it.name]}
                onRemovePress={handleRemove}
              />
            ))}
          </TableBody>
        </Table>
      </HideConditional>
    </HealthLayout>
  );
};

export default observer(CompaniesPage);
