import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { __NEW__ } from '../../../constants';
import jsonHelper from '../../../helpers/jsonHelper';
import firebase from '../../../settings/firebase-settings';
import User from '../entities/User';

const db = firebase.firestore;
const collection_name = 'main_users';

const _addUid = (entity: User, uid?: string) => {
  if (!entity.firebaseUids) {
    entity.firebaseUids = [];
  }
  if (!uid) {
    return;
  }
  if (entity.firebaseUids.includes(uid)) {
    return;
  }
  entity.firebaseUids.push(uid);
};

const add = async (entity: User) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;
  _addUid(entity, firebase.auth.currentUser?.uid);

  try {
    await setDoc(doc(db, collection_name, new_uuid), {
      ...entity,
    });

    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const update = async (id: string, entity: User) => {
  const docRef = doc(db, collection_name, id);
  entity.updatedAt = Date.now();
  _addUid(entity, firebase.auth.currentUser?.uid);

  try {
    await updateDoc(docRef, { ...entity });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getCurrentUser = async (authEmail: string) => {
  const email = firebase.auth.currentUser?.email ?? authEmail;
  if (!email) {
    return new User();
  }
  return getByEmail(email);
};

const getByEmail = async (email: string) => {
  const q = query(collection(db, collection_name), where('email', '==', email), limit(1));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    const newUser = new User();
    newUser.id = __NEW__;
    newUser.email = email;
    newUser.name = email;
    return newUser;
  }
  return snapshot.docs.map(doc => jsonHelper.castTo<User>({ ...doc.data(), id: doc.id }))[0];
};

const userService = {
  add,
  update,
  getCurrentUser,
  getByEmail,
};

export default userService;
