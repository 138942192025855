import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import jsonHelper from '../../../helpers/jsonHelper';

import firebase from '../../../settings/firebase-settings';
import Patient from '../entities/Patient';

const db = firebase.firestore;
const collection_name = 'health_patients';

const add = async (entity: Patient) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;

  try {
    await setDoc(doc(db, collection_name, new_uuid), {
      ...entity,
    });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const update = async (id: string, entity: Patient) => {
  const docRef = doc(db, collection_name, id);
  entity.updatedAt = Date.now();

  try {
    await updateDoc(docRef, { ...entity });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const remove = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  try {
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAll = async () => {
  const q = query(collection(db, collection_name), orderBy('createdAt', 'desc'));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => jsonHelper.castTo<Patient>({ ...doc.data(), id: doc.id }));
};

const getById = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists) {
    return undefined;
  }
  return jsonHelper.castTo<Patient>({ ...snapshot.data(), id: snapshot.id });
};

const getByName = async (name: string) => {
  const q = query(collection(db, collection_name), where('name', '==', name));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => jsonHelper.castTo<Patient>({ ...doc.data(), id: doc.id }));
};

const getAge = (birth_date: string | undefined) => {
  if (!birth_date) {
    return 0;
  }
  const diff_ms = Date.now() - new Date(birth_date).getTime();
  const age_dt = new Date(diff_ms);
  const years = Math.abs(age_dt.getUTCFullYear() - 1970);
  return years;
};

const validator = (entity: Patient, action: 'add' | 'update') =>
  new Promise(async (resolve, reject) => {
    if (entity.name === '' || entity.name.length <= 3) return reject('Preencha o campo nome');

    if (!entity.genre) return reject('Preencha o campo sexo');

    if (action === 'add') {
      const r = await getByName(entity.name);
      if (r.length > 0) {
        return reject('O nome informado já está cadastrado');
      }
    }

    return resolve(true);
  });

const patientService = {
  add,
  remove,
  update,
  getAll,
  getById,
  getByName,
  getAge,
  validator,
};

export default patientService;
