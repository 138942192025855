import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { InputText } from '../../../../components/Form';

import { __NEW__ } from '../../../../constants';
import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import { useStore } from '../../../../stores/RootStore';
import HealthLayout from '../../components/Layout';
import Company from '../../entities/Company';
import companyService from '../../services/companyService';

const CompaniesEditPage = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const { setMessage } = useStore('uiState');
  const [entity, setEntity] = useState<Company>(new Company(''));
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (id === __NEW__ || entity.id) {
      return;
    }

    setLoading(true);
    companyService
      .getById(id)
      .then(value => setEntity(value ?? entity))
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }, [id, entity]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleError = (error: any) => {
    setMessage('error', typeof error === 'string' ? error : JSON.stringify(error));
  };
  const handleAdd = () => {
    companyService
      .add(entity)
      .then(() => {
        setMessage('success', 'Registro criado com sucesso');
        history.push('/health/companies');
      })
      .catch(handleError);
  };
  const handleUpdate = () => {
    companyService
      .update(id, entity)
      .then(() => {
        setMessage('success', 'Registro atualizado com sucesso');
        history.push('/health/companies');
      })
      .catch(handleError);
  };
  const handleSave = () => {
    setSaving(true);
    companyService
      .validator(entity, id === __NEW__ ? 'add' : 'update')
      .then(() => (id === __NEW__ ? handleAdd() : handleUpdate()))
      .catch(handleError)
      .finally(() => setSaving(false));
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/health' },
        { label: 'Minhas clínicas', value: '/health/companies' },
        { label: id === __NEW__ ? 'Nova' : 'Editar', value: `/health/companies/${id}` },
      ]}
    />
  );

  const PageHeader = () => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h1>Clínicas</h1>

      <div>
        <div className="d-inline-block btn-group" role="group">
          <Link to="/health/companies" className="btn btn-secondary">
            Cancelar
          </Link>
          <button disabled={saving} onClick={handleSave} className="btn btn-dark">
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader />
      <Loading active={loading} />

      <HideConditional condition={loading}>
        <div className="row">
          <InputText
            name="name"
            className="col-5"
            label="Nome completo"
            value={entity.name}
            onChange={handleInputChange}
          />

          <InputText
            name="docto"
            className="col"
            label="CNPJ/CPF"
            value={entity.docto}
            placeholder="000.000.000-00 ou 00.000.000/0000-00"
            onChange={handleInputChange}
          />

          <InputText
            name="email"
            type="email"
            className="col"
            label="E-mail"
            value={entity.email}
            placeholder="name@example.com"
            onChange={handleInputChange}
          />

          <InputText
            name="site"
            className="col"
            label="Site"
            value={entity.site}
            onChange={handleInputChange}
          />
        </div>

        <div className="row">
          <InputText
            name="address"
            className="col"
            label="Endereço"
            value={entity.address}
            onChange={handleInputChange}
          />

          <InputText
            name="telephone"
            type="tel"
            className="col"
            label="Telefone"
            placeholder="(xx) 0000-0000"
            value={entity.telephone}
            onChange={handleInputChange}
          />
        </div>
      </HideConditional>
    </HealthLayout>
  );
};

export default CompaniesEditPage;
