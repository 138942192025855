import { createContext, useContext } from 'react';

import { AvaliacaoTmaStore } from './AvaliacaoTmaStore';
import { CompanyStore } from './CompanyStore';
import { PatientStore } from './PatientStore';

export const stores = Object.freeze({
  companyState: new CompanyStore(),
  patientState: new PatientStore(),
  avaliacaoTmaState: new AvaliacaoTmaStore(),
});

export const storesContext = createContext(stores);
export const StoresProvider = storesContext.Provider;

export const useHealthStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
  useContext(storesContext)[store];
