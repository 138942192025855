import '../assets/css/spinner.css';

interface IProps {
  active?: boolean;
}

const Loading = ({ active }: IProps) => {
  return active ? (
    <div className="text-center">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
