interface IProps {
  condition?: boolean;
  children?: any;
}

const HideConditional = ({ condition, children }: IProps) => {
  return condition ? <></> : <>{children}</>;
};

export default HideConditional;
