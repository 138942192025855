interface IProps {
  label?: string;
  name: string;
  value?: any;
  type?: 'text' | 'email' | 'tel';
  placeholder?: string;
  className?: string;
  autoComplete?: string;
  required?: boolean;
  onChange?: (event: any) => void;
}

const InputText = ({
  label,
  name,
  value,
  type,
  placeholder,
  className,
  autoComplete,
  required,
  onChange,
}: IProps) => {
  return (
    <div className={`mb-3 ${className ?? ''}`}>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <input
        type={type ? type : 'text'}
        className="form-control"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        onChange={onChange}
      />
    </div>
  );
};

export default InputText;
