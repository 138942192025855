import Breadcrumb from '../../../../shared/Breadcrumb';
import PageHeader from '../../../../shared/PageHeader';
import HealthLayout from '../../components/Layout';

const MainProjectPage = () => {
  const renderPath = () => <Breadcrumb items={[{ label: 'Início', value: '/health' }]} />;

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader title="Dashboard" />
    </HealthLayout>
  );
};

export default MainProjectPage;
