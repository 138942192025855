import { makeAutoObservable } from 'mobx';
import Timesheet from '../entities/Timesheet';
import timesheetService from '../services/timesheetService';

export class TimesheetStore {
  storageKey = '@TimesheetStore';

  current?: Timesheet;
  loadind_current = false;

  items: Timesheet[] = [];
  loading_items = false;

  saving = false;

  errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  // > GET current ------------------------------------------------------------
  // getCurrent = (email: string) => {
  //   this.current = undefined;
  //   this.loadind_current = true;
  //   this.errors = [];
  //   timesheetService.getCurrentUser(email).then(this.getCurrentSucces).catch(this.getCurrentFailure);
  // };
  // getCurrentSucces = (value: User) => {
  //   this.current = value;
  //   this.loadind_current = false;
  //   this.errors = [];
  // };
  // getCurrentFailure = (error: any) => {
  //   this.current = undefined;
  //   this.loadind_current = false;
  //   this.defaultFailure(error);
  // };
  // < GET current ------------------------------------------------------------

  // > ADD/UPDATE user --------------------------------------------------------
  add = async (value: Timesheet) => {
    this.errors = [];
    this.saving = true;

    const valid = await timesheetService
      .validator(value, 'add')
      .then(value => value === true)
      .catch(this.saveFailure);

    if (valid) {
      return await timesheetService.add(value).then(this.saveSuccess).catch(this.saveFailure);
    }
  };
  update = async (value: Timesheet) => {
    if (!value.id) {
      this.errors = ['Invalid id'];
      return;
    }
    this.errors = [];
    this.saving = true;

    const valid = await timesheetService
      .validator(value, 'update')
      .then(value => value === true)
      .catch(this.saveFailure);

    if (valid) {
      return await timesheetService
        .update(value.id, value)
        .then(this.saveSuccess)
        .catch(this.saveFailure);
    }
  };
  saveSuccess = (value: boolean) => {
    this.saving = false;

    if (value !== true) {
      this.errors = ['Fail on add user'];
      return null;
    }

    return 'Registro salvo com sucesso.';
  };
  saveFailure = (error: any) => {
    this.saving = false;
    this.defaultFailure(error);
    return null;
  };
  // < ADD/UPDATE user --------------------------------------------------------

  defaultFailure = (error: any) => {
    this.errors = typeof error === 'string' ? [error] : [JSON.stringify(error)];
    console.log(error);
  };
}
