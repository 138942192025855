import { UserCredential } from 'firebase/auth';
import { makeAutoObservable } from 'mobx';

import User from '../modules/main/entities/User';
import { StorageService } from '../services/StorageService';
import firebase from '../settings/firebase-settings';

const allowedUids = ['MeVugYf7BHSQ1rk3qmcwIkOthFQ2', '3Ya5DIIYyXV5yPoWvwB4CsNwlmk1'];

const storage = new StorageService();

export class AuthStore {
  storageKey = '@AuthStore';

  authenticated = false;
  user?: User;
  error?: string;

  constructor() {
    makeAutoObservable(this);
    storage.loadFromStorage(this.storageKey)?.then(this.load);
  }

  load = (storage: AuthStore) => {
    console.log({ authStore: storage });

    if (storage === null) {
      return;
    }
    this.authenticated = storage.authenticated;
    this.user = storage.user;
  };

  login = (email: string, password: string) => {
    firebase
      .signInWithEmailAndPassword(firebase.auth, email, password)
      .then(this.loginSuccess)
      .catch(this.loginFailure);
  };

  loginSuccess = (resp: UserCredential) => {
    if (!allowedUids.includes(resp.user.uid)) {
      this.authenticated = false;
      this.user = undefined;
      this.error = 'User is not allowed to access';
      storage.persistToStorage(this.storageKey, this);
      return;
    }

    this.authenticated = true;
    this.user = resp.user as any;
    this.error = undefined;
    storage.persistToStorage(this.storageKey, this);
  };

  loginFailure = (error: any) => {
    if (error.code === 'auth/user-not-found') {
      this.error = 'Usuário não encontrado.';
    } else if (error.code === 'auth/wrong-password') {
      this.error = 'Usuário ou senha inválidos.';
    } else if (error.code === 'auth/too-many-requests') {
      this.error = 'Muitas tentativas. Acesso bloqueado.';
    } else {
      this.error = JSON.stringify(error);
      console.error(error);
    }
    this.authenticated = false;
    storage.persistToStorage(this.storageKey, this);
  };

  logout = () => {
    this.authenticated = false;
    this.user = undefined;
    storage.persistToStorage(this.storageKey, this);
  };
}
