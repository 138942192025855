import Layout from '../../../shared/Layout';
import HealthNavbar from './Navbar';

interface IProps {
  breadcrumb?: any;
  children?: any;
}

const HealthLayout = ({ breadcrumb, children }: IProps) => (
  <Layout breadcrumb={breadcrumb} navbar={HealthNavbar}>
    {children}
  </Layout>
);

export default HealthLayout;
