import { makeAutoObservable } from 'mobx';

import AvaliacaoTma from '../entities/AvaliacaoTma';
import avaliacaoTmaService from '../services/avaliacaoTmaService';

export class AvaliacaoTmaStore {
  storageKey = '@AvaliacaoTmaStore';

  current?: any;
  loadind_current = false;

  errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getById = (id: string) => {
    this.current = undefined;
    this.loadind_current = false;
    this.errors = [];

    avaliacaoTmaService.getById(id).then(this.setCurrentSucces).catch(this.setCurrentFailure);
  };

  setCurrentSucces = (value?: AvaliacaoTma) => {
    this.current = value;
    this.loadind_current = false;
  };

  setCurrentFailure = (error: any) => {
    this.current = undefined;
    this.loadind_current = false;
    this.errors = [JSON.stringify(error)];
    console.log(error);
  };
}
