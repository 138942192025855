import Layout from '../../../shared/Layout';
import ChangemanNavbar from './Navbar';

interface IProps {
  breadcrumb?: any;
  children?: any;
}

const ChangemanLayout = ({ breadcrumb, children }: IProps) => (
  <Layout breadcrumb={breadcrumb} navbar={ChangemanNavbar}>
    {children}
  </Layout>
);

export default ChangemanLayout;
