import FlashMessage from './FlashMessage';
import Footer from './Footer';
import Navbar from './Navbar';

interface IProps {
  breadcrumb?: any;
  children?: any;
  navbar?: any;
}

const Layout = ({ breadcrumb: PagePath, children, navbar }: IProps) => {
  return (
    <>
      <header>
        <Navbar navbar={navbar} />
        {PagePath ? <PagePath /> : undefined}
      </header>

      <div className="container" style={{ paddingTop: 15, paddingBottom: 40 }}>
        <FlashMessage />
        {children}
      </div>

      <Footer />
    </>
  );
};

export default Layout;
