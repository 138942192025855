import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import { useStore } from '../../../../stores/RootStore';
import patientService from '../../services/patientService';
import { useHealthStore } from '../../stores/RootStore';

const ReportAvaliacaoTma = () => {
  const { patientId, id } = useParams<any>();
  const { setMessage } = useStore('uiState');
  const { loadind_current, current, errors, getById } = useHealthStore('patientState');
  const {
    loadind_current: tma_loadind_current,
    current: tma_current,
    errors: tma_errors,
    getById: tma_getById,
  } = useHealthStore('avaliacaoTmaState');

  useEffect(() => {
    if (!patientId) {
      return;
    }
    getById(patientId);
  }, [getById, patientId]);

  useEffect(() => {
    if (current?.id && !tma_current?.id) {
      tma_getById(id);
    }
  }, [current?.id, id, tma_current?.id, tma_getById]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setMessage('error', JSON.stringify(errors));
    }
  }, [errors, setMessage]);

  return (
    <>
      {console.log({ patientId, id })}
      <Loading active={loadind_current || tma_loadind_current} />
      <HideConditional condition={loadind_current || tma_loadind_current}>
        <div className="card card-body mb-3 d-flex flex-row justify-content-between">
          <div>Nome: {current?.name}</div>
          <div>Profissão: {current?.occupation}</div>
          <div>Idade: {patientService.getAge(current?.birth_date)}</div>
        </div>
        <div className="card card-body mb-3">
          <div>Queixa principal: {tma_current.queixa_principal}</div>
          <div>Quando iniciou: {tma_current.quando_iniciou}</div>
          <div>Evolução da dor: {tma_current.evolucao_da_dor}</div>
          <div>EVA: {tma_current.evaRange}</div>
          <div>O que impede de fazer: {tma_current.impedimentos}</div>
        </div>
      </HideConditional>
    </>
  );
};

export default observer(ReportAvaliacaoTma);
