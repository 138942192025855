import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/images/logo-2.png';
import hashHelper from '../helpers/hashHelper';
import { useStore } from '../stores/RootStore';

interface IProps {
  navbar?: any;
}

const Navbar = ({ navbar: ModuleNavbar }: IProps) => {
  const { user, logout } = useStore('authState');
  const location = useLocation();
  const email = user?.email;
  const image: any = null;
  const emailHash = email ? hashHelper.MD5(email) : null;

  const pathname = location.pathname;
  const module = pathname.startsWith('/health')
    ? 'Health'
    : pathname.startsWith('/project')
    ? 'PM'
    : pathname.startsWith('/changeman')
    ? 'CM'
    : '';

  const renderLogoAndToggle = () => (
    <>
      <Link className="navbar-brand flex-grow-1 align-items-center d-flex" to="/">
        <img src={logo} alt="BeHold" height={40} />
        <span className="module">{module}</span>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mainNavbar"
        aria-controls="mainNavbar"
        aria-expanded="true"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
    </>
  );

  const renderUserMenu = () => (
    <div className="dropdown ps-lg-3">
      <a
        href="/#"
        className="d-block link-light text-decoration-none dropdown-toggle"
        id="dropUser"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <img
          src={image ? image : emailHash ? `https://www.gravatar.com/avatar/${emailHash}` : null}
          alt={email}
          width="40"
          height="40"
          className="rounded-circle border border-2 d-none d-lg-inline"
        />
        <span className="d-inline d-lg-none">{user?.email}</span>
      </a>
      <ul
        className="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end text-small"
        aria-labelledby="dropUser">
        <li>
          <Link className="dropdown-item" to="/profile">
            Perfil de usuário
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Link className="dropdown-item" to="/login" onClick={logout}>
            Sair
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        {renderLogoAndToggle()}

        <div className="collapse navbar-collapse justify-content-end" id="mainNavbar">
          <ul className="navbar-nav md-auto mb-2 mb-lg-0">
            {!ModuleNavbar ? (
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Início
                </Link>
              </li>
            ) : undefined}

            {ModuleNavbar ? <ModuleNavbar /> : undefined}

            {/* <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/health/patients">
                Pacientes
              </Link>
            </li> */}
          </ul>

          {renderUserMenu()}
        </div>
        {/* End menu */}
      </div>
    </nav>
  );
};

export default Navbar;
