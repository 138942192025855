import React from 'react';
import { Link } from 'react-router-dom';

interface IPath {
  label: string;
  value: string;
}

interface IProps {
  items: IPath[];
}

const Breadcrumb: React.FC<IProps> = ({ items }: IProps) => {
  return (
    <div className="py-2 bg-light border-bottom">
      <nav className="container d-flex flex-row" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          {items.map((item, i) =>
            items.length - 1 === i ? (
              <li key={i} className="breadcrumb-item active" aria-current="page">
                {item.label}
              </li>
            ) : item.value ? (
              <li key={i} className="breadcrumb-item" aria-current="page">
                <Link to={item.value}>{item.label}</Link>
              </li>
            ) : (
              <li key={i} className="breadcrumb-item" aria-current="page">
                {item.label}
              </li>
            ),
          )}
        </ol>
        <div className="flex-grow-1 text-end">{/* <div role="button">***</div> */}</div>
      </nav>
    </div>
  );
};

export default Breadcrumb;
