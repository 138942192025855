import { Link } from 'react-router-dom';

interface IAction {
  title: string;
  url?: string;
  disable?: boolean;
  onClick?: () => void;
}

interface IProps {
  title: string;
  actions?: IAction[];
}

const PageHeader = ({ title, actions }: IProps) => (
  <div className="d-flex justify-content-between align-items-center mb-3">
    <h1>{title}</h1>
    <div className="d-block btn-group" role="group">
      {actions?.map((a, i) =>
        a.url ? (
          <Link
            key={i}
            to={a.url}
            className={`btn btn-${
              i !== actions.length - 1 && actions.length > 1 ? 'secondary' : 'dark'
            }`}>
            {a.title}
          </Link>
        ) : (
          <button
            key={i}
            className={`btn btn-${i !== actions.length - 1 && i % 2 === 0 ? 'secondary' : 'dark'}`}
            type="button"
            disabled={a.disable}
            onClick={a.onClick}>
            {a.title}
          </button>
        ),
      )}
    </div>
  </div>
);

export default PageHeader;
