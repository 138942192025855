import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, uploadBytes } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyC4ywcrqQI9A2DG6bUdkmCD5KNirxTw4iE',
  authDomain: 'behold-3bf05.firebaseapp.com',
  projectId: 'behold-3bf05',
  storageBucket: 'behold-3bf05.appspot.com',
  messagingSenderId: '657213675046',
  appId: '1:657213675046:web:b9df4c2df43d26aa28b8e7',
  measurementId: 'G-72PL1WZ2CS',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const realtimedb = getDatabase(app);

const firebase = {
  auth,
  firestore,
  storage,
  realtimedb,
  uploadBytes,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
};

export default firebase;
