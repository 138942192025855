import { makeAutoObservable } from 'mobx';

import { StorageService } from '../services/StorageService';
import { TMessage } from '../types/TMessage';

interface IFlashMessage {
  message: string;
  type: TMessage;
}

const storage = new StorageService();

export class UiStore {
  storageKey = '@UiStore';

  language = 'pt_BT';
  flashMessage: IFlashMessage | undefined;

  constructor() {
    makeAutoObservable(this);
    storage.loadFromStorage(this.storageKey)?.then(this.load);
  }

  load = (storage: UiStore) => {
    console.log({ uistore: storage });

    if (storage === null) {
      return;
    }
    this.language = storage.language;
  };

  setMessage = (type: TMessage, message: string) => {
    this.flashMessage = { type, message };
  };

  clearMessage = () => {
    this.flashMessage = undefined;
  };
}
