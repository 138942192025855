import TableField from './TableField';

interface IProps {
  id?: string;
  linkTo?: string;
  values: any[];
  classList?: any[];
  onRemovePress?: (id: string) => void;
}

const TableLine: React.FC<IProps> = ({ id, linkTo, values, classList, onRemovePress }: IProps) => {
  const handleRemove = () => {
    const r = window.confirm(`Deseja excluir o registro '${id}'? \nEssa ação é irreversível.`);

    if (!id || !r || !onRemovePress) {
      return;
    }

    onRemovePress(id);
  };

  return (
    <tr>
      {values.map((v, k) => (
        <TableField key={k} linkTo={linkTo} classList={classList} k={k} value={v} />
      ))}
      {onRemovePress ? (
        <td className="p-0 pt-1 px-1 text-end">
          <button className="btn btn-danger btn-sm" onClick={handleRemove}>
            Excluir
          </button>
        </td>
      ) : undefined}
    </tr>
  );
};

export default TableLine;
