import { child, get, ref, set } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

import jsonHelper from '../../../helpers/jsonHelper';
import firebase from '../../../settings/firebase-settings';
import TextContent from '../entities/TextContent';

const db = firebase.realtimedb;
const collection_name = 'text_contents';

const add = async (entity: TextContent) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;

  try {
    const textRef = ref(db, `${collection_name}/${entity.name.replace('.', '_')}`);
    set(textRef, entity);
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const remove = async (name: string) => {
  const textRef = ref(db, `${collection_name}/${name.replace('.', '_')}`);
  set(textRef, null);
};

const getByName = async (name: string) => {
  const textRef = ref(db, collection_name);
  var snapshot = await get(child(textRef, name.replace('.', '_')));
  if (!snapshot.exists()) {
    return undefined;
  }
  return jsonHelper.castTo<TextContent>({ ...snapshot.val(), id: snapshot.key });
};

const validator = (entity: TextContent, action: 'add') =>
  new Promise(async (resolve, reject) => {
    if (entity.name === '' || entity.name.length <= 3) return reject('Preencha o campo nome');
    if (entity.content === '' || entity.content.length <= 3) return reject('Preencha o conteúdo');

    if (action === 'add') {
      const r = await getByName(entity.name);
      if (r) {
        return reject('O nome informado já está cadastrado');
      }
    }

    return resolve(true);
  });

const textService = {
  add,
  remove,
  getByName,
  validator,
};

export default textService;
