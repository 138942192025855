import { __NEW__ } from '../../../constants';

class TextContent {
  id: string;
  name: string;
  content: string;

  createdAt!: number;
  updatedAt!: number;

  constructor() {
    this.id = __NEW__;
    this.name = 'content.txt';
    this.content = 'c2V1IGNvbnRldWRvIGFxdWk=';
  }
}

export default TextContent;
