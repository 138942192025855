import { createContext, useContext } from 'react';

import { UiStore } from './UiStore';
import { AuthStore } from './AuthStore';

export const stores = Object.freeze({
  uiState: new UiStore(),
  authState: new AuthStore(),
});

export const storesContext = createContext(stores);
export const StoresProvider = storesContext.Provider;

export const useStores = () => useContext(storesContext);
export const useStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
  useContext(storesContext)[store];
