import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import jsonHelper from '../../../helpers/jsonHelper';
import firebase from '../../../settings/firebase-settings';
import Company from '../entities/Company';

const db = firebase.firestore;
const collection_name = 'health_companies';

const add = async (entity: Company) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;

  try {
    await setDoc(doc(db, collection_name, new_uuid), {
      ...entity,
    });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const update = async (id: string, entity: Company) => {
  const docRef = doc(db, collection_name, id);
  entity.updatedAt = Date.now();

  try {
    await updateDoc(docRef, { ...entity });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const remove = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  try {
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getById = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists) {
    return undefined;
  }
  return jsonHelper.castTo<Company>({ ...snapshot.data(), id: snapshot.id });
};

const getByOwnerId = async (ownerId: string) => {
  const q = query(collection(db, collection_name), where('ownerId', '==', ownerId));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => jsonHelper.castTo<Company>({ ...doc.data(), id: doc.id }));
};

const validator = (entity: Company, action: 'add' | 'update') =>
  new Promise(async (resolve, reject) => {
    if (entity.name === '' || entity.name.length <= 3) return reject('Preencha o campo nome');

    return resolve(true);
  });

const companyService = {
  add,
  remove,
  update,
  getByOwnerId,
  getById,
  validator,
};

export default companyService;
