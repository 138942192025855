import { makeAutoObservable } from 'mobx';

import TextContent from '../entities/TextContent';
import textService from '../services/textService';

export class TextStore {
  storageKey = '@TextStore';

  current?: TextContent;
  loadind_current = false;

  items: TextContent[] = [];
  loading_items = false;

  saving = false;

  errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  add = async (value: TextContent) => {
    this.errors = [];
    this.saving = true;

    const valid = await textService
      .validator(value, 'add')
      .then(value => value === true)
      .catch(this.saveFailure);

    if (valid) {
      return textService.add(value).then(this.saveSuccess).catch(this.saveFailure);
    }
  };
  saveSuccess = (value: boolean) => {
    this.saving = false;

    if (value !== true) {
      this.errors = ['Fail on add user'];
      return;
    }

    return 'Registro salvo com sucesso.';
  };
  saveFailure = (error: any) => {
    this.saving = false;
    this.defaultFailure(error);
  };
  defaultFailure = (error: any) => {
    this.errors = typeof error === 'string' ? [error] : [JSON.stringify(error)];
    console.log(error);
  };

  setItemsSuccess = (value: TextContent[]) => {
    this.items = value;
    this.loading_items = false;
  };

  setItemsFailure = (error: any) => {
    this.items = [];
    this.loading_items = false;
    this.errors = [JSON.stringify(error)];
    console.log(error);
  };

  getByName = (name: string) => {
    this.current = undefined;
    this.loadind_current = true;
    this.errors = [];

    textService.getByName(name).then(this.setCurrentSuccess).catch(this.setCurrentFailure);
  };

  setCurrentSuccess = (value?: TextContent) => {
    this.current = value;
    this.loadind_current = false;
  };

  setCurrentFailure = (error: any) => {
    this.current = undefined;
    this.loadind_current = false;
    this.errors = [JSON.stringify(error)];
    console.log(error);
  };

  setRemoveSuccess = () => {
    console.log('setRemoveSuccess', this.current?.content);

    this.current = new TextContent();
    this.current.content = '';
    this.loadind_current = false;
    return 'Registro excluído com sucesso.';
  };

  removeItem = async (name: string) => {
    this.loadind_current = true;
    return await textService.remove(name).then(this.setRemoveSuccess).catch(this.setCurrentFailure);
  };
}
