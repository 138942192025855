import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import firebase from '../../settings/firebase-settings';
import { useHistory } from 'react-router-dom';

const CreateAccount: React.FC = () => {
  const history = useHistory();

  // const { login } = useStore('userState');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'emailInput') setEmail(e.target.value);
    if (e.target.name === 'passwordInput') setPassword(e.target.value);
  };

  const handleCreateAccount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('prevent default');

    firebase
      .createUserWithEmailAndPassword(firebase.auth, email, password)
      .then(resp => {
        // login(resp.user);
        history.push('/');
      })
      .catch((error: any) => {
        if (error.code === 'auth/email-already-in-use') {
          setErrorMessage('Já existe uma conta com esse e-mail');
        } else if (error.code === 'auth/invalid-email') {
          setErrorMessage('E-mail inválido');
        } else {
          console.error(JSON.stringify(error));
          setErrorMessage(JSON.stringify(error));
        }
      });
  };

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <div className="login__container">
      <div className="login__left">
        <div className="login__form-box">
          <img src={logo} alt="Be Hold" />

          <form method="post" onSubmit={handleCreateAccount}>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                name="emailInput"
                placeholder="name@example.com"
                value={email}
                onChange={handleChange}
                autoComplete="new-username"
              />
              <label htmlFor="emailInput">E-mail</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                name="passwordInput"
                placeholder="Password"
                value={password}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <label htmlFor="passwordInput">Senha</label>
            </div>

            <div className="d-grid gap-2 mb-3">
              <button className="btn btn-secondary" type="submit">
                Criar
              </button>
              <button type="button" className="btn btn-link" onClick={handleLogin}>
                Efetuar login
              </button>
            </div>

            {errorMessage ? (
              <div className="alert alert-danger" role="alert" style={{ maxWidth: '330px' }}>
                {errorMessage}
              </div>
            ) : undefined}
          </form>
        </div>
      </div>
      <div className="login__right"></div>
    </div>
  );
};

export default CreateAccount;
