import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../stores/RootStore';

const FlashMessage = () => {
  const { flashMessage, clearMessage } = useStore('uiState');

  useEffect(() => {
    if (flashMessage) {
      setTimeout(() => clearMessage(), 3000);
    }
  }, [clearMessage, flashMessage]);

  if (!flashMessage?.message) {
    return <></>;
  }

  return (
    <div
      className={`alert alert-dismissible ${
        flashMessage.type === 'success'
          ? 'alert-success'
          : flashMessage.type === 'warning'
          ? 'alert-warning'
          : 'alert-danger'
      }`}
      role="alert">
      {flashMessage?.message}
    </div>
  );
};

export default observer(FlashMessage);
