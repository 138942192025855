class AvaliacaoTma {
  id: string | undefined;

  title: string = 'Avaliação Terapia Mecânica Avançada (TMA)';
  patientId: string;

  queixa_principal: string;
  quando_iniciou: string;
  evolucao_da_dor: string;

  evaRange: number;

  impedimentos: string;

  tipo_de_dor_1: boolean;
  tipo_de_dor_2: boolean;
  tipo_de_dor_3: boolean;
  tipo_de_dor_4: boolean;

  o_que_melhora: string;
  o_que_piora: string;

  acorda_com_dor: boolean;
  piora_durante_dia: boolean;

  trauma_recente_relacionado_a_dor: string;
  perda_de_peso_inexplicada: boolean;
  febre_inexplicada: boolean;
  dor_de_madrugada: boolean;
  historico_de_cancer: string;
  incontinencia_urinaria: boolean;
  incontinencia_fecal: boolean;
  dormencia_em_sela: boolean;
  pe_caido: boolean;
  perda_da_forca_abrupta: string;

  tratamentos_anteriores: string;
  medicacao: string;
  cirurgias: string;
  traumas: string;
  cicatrizes: string;
  visceral: string;
  sono: string;
  alimentacao: string;
  atividade_fisica: string;
  exame_de_image_achados: string;
  inspecao_estatica: string;
  inspecao_dinamica: string;
  testes_especificos: string;

  captores_olhos: string;
  captores_atm: string;
  captores_pe: string;

  ownerId: string;
  companyId: string;

  createdAt!: number;
  updatedAt!: number;

  teste_lombar_sintomas_pre_teste: string = '';
  teste_lombar: TesteItem[] = [];
  teste_cervical_sintomas_pre_teste: string = '';
  teste_cervical: TesteItem[] = [];

  constructor(ownerId: string, companyId: string, patientId: string) {
    this.patientId = patientId;

    this.queixa_principal = '';
    this.quando_iniciou = '';
    this.evolucao_da_dor = '';

    this.evaRange = 0;

    this.impedimentos = '';

    this.tipo_de_dor_1 = false;
    this.tipo_de_dor_2 = false;
    this.tipo_de_dor_3 = false;
    this.tipo_de_dor_4 = false;

    this.o_que_melhora = '';
    this.o_que_piora = '';

    this.acorda_com_dor = false;
    this.piora_durante_dia = false;

    this.trauma_recente_relacionado_a_dor = '';
    this.perda_de_peso_inexplicada = false;
    this.febre_inexplicada = false;
    this.dor_de_madrugada = false;
    this.historico_de_cancer = '';
    this.incontinencia_urinaria = false;
    this.incontinencia_fecal = false;
    this.dormencia_em_sela = false;
    this.pe_caido = false;
    this.perda_da_forca_abrupta = '';

    this.tratamentos_anteriores = '';
    this.medicacao = '';
    this.cirurgias = '';
    this.traumas = '';
    this.cicatrizes = '';
    this.visceral = '';
    this.sono = '';
    this.alimentacao = '';
    this.atividade_fisica = '';
    this.exame_de_image_achados = '';
    this.inspecao_estatica = '';
    this.inspecao_dinamica = '';
    this.testes_especificos = '';

    this.captores_olhos = '';
    this.captores_atm = '';
    this.captores_pe = '';

    this.teste_lombar.push(new TesteItem('FLX EM PÉ REP.'));
    this.teste_lombar.push(new TesteItem('EXT EM PÉ REP.'));
    this.teste_lombar.push(new TesteItem('EXT DEITADO'));
    this.teste_lombar.push(new TesteItem('EXT DEITADO REP.'));

    this.teste_cervical.push(new TesteItem('RETRAÇÃO REP. SENTADO'));
    this.teste_cervical.push(new TesteItem('FLEXÃO REP. SENTADO'));
    this.teste_cervical.push(new TesteItem('RETRAÇÃO REP. DEITADO'));

    this.ownerId = ownerId;
    this.companyId = companyId;
  }
}

class TesteItem {
  nome_teste: string;
  sintomas_durante_teste: string;
  sintomas_apos_teste: string;
  mais_adm: boolean;
  menos_adm: boolean;
  ne: boolean;

  constructor(nome_teste: string) {
    this.nome_teste = nome_teste;
    this.sintomas_durante_teste = '';
    this.sintomas_apos_teste = '';
    this.mais_adm = false;
    this.menos_adm = false;
    this.ne = false;
  }
}

export { TesteItem };
export default AvaliacaoTma;
