import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import { __NEW__ } from '../../../../constants';
import dateHelper from '../../../../helpers/dateHelper';
import Breadcrumb from '../../../../shared/Breadcrumb';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import ProjectLayout from '../../components/Layout';
import Timesheet from '../../entities/Timesheet';
import { useProjectStore } from '../../stores/RootStore';

const TimesheetPage = () => {
  const { setMessage } = useStore('uiState');
  const { add, update, errors, saving } = useProjectStore('timesheetState');
  const [entity, setEntity] = useState(new Timesheet(''));

  useEffect(() => {
    if (errors && errors.length > 0) {
      setMessage('error', JSON.stringify(errors));
    }
  }, [errors, setMessage]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (!entity.id || saving) {
      return;
    }

    if (entity.id === __NEW__) {
      const result = await add(entity);
      if (result) {
        setMessage('success', result);
      }
      return;
    }

    const result = await update(entity);
    if (result) {
      setMessage('success', result);
    }
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/project' },
        { label: 'Timesheet', value: '/project/timesheet' },
      ]}
    />
  );

  return (
    <ProjectLayout breadcrumb={renderPath}>
      <PageHeader title="Apontamento" actions={[{ title: 'Salvar', onClick: handleSave }]} />
      <div>
        <div className="mb-3">
          <input
            type="date"
            name="date"
            id="date"
            value={dateHelper.format(new Date(entity.date), 'yyyy-MM-dd')}
            className="form-control"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3 d-flex flex-row justify-content-between">
          <input
            type="number"
            name="startHour"
            id="startHour"
            required
            className="form-control"
            onChange={handleInputChange}
          />
          :
          <input
            type="number"
            name="startMinute"
            id="startMinute"
            required
            className="form-control"
            onChange={handleInputChange}
          />
          <div className="p-2" />
          <input
            type="number"
            name="endHour"
            id="endHour"
            className="form-control"
            onChange={handleInputChange}
          />
          :
          <input
            type="number"
            name="endMinute"
            id="endMinute"
            className="form-control"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="clientName"
            id="clientName"
            value={entity.clientName}
            list="clientList"
            className="form-control"
            placeholder="Cliente"
            onChange={handleInputChange}
          />
          <datalist id="clientList">
            <option value="Itaú">Itaú</option>
            <option value="Policoders">Policoders</option>
            <option value="Talk And Live">Talk And Live</option>
          </datalist>
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="project"
            id="project"
            value={entity.project}
            list="projectList"
            className="form-control"
            placeholder="Projeto"
            onChange={handleInputChange}
          />
          <datalist id="projectList">
            <option value="Behold Health">Behold Health</option>
            <option value="Itaú">Itaú</option>
            <option value="Talk And Live">Talk And Live</option>
            <option value="Totalmix">Totalmix</option>
          </datalist>
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="type"
            id="type"
            value={entity.type}
            list="typeList"
            className="form-control"
            placeholder="Tipo de tarefa"
            onChange={handleInputChange}
          />
          <datalist id="typeList">
            <option value="Desenvolvimento">Desenvolvimento</option>
            <option value="Estudo">Estudo</option>
            <option value="Reunião">Reunião</option>
          </datalist>
        </div>

        <div className="mb-3">
          <textarea
            name="description"
            id="description"
            value={entity.description}
            className="form-control"
            placeholder="Descrição"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="document"
            id="document"
            value={entity.document}
            list="documentList"
            className="form-control"
            placeholder="Proposta"
            onChange={handleInputChange}
          />
          <datalist id="documentList">
            <option value="P2108171145">P2108171145 Talk And Live</option>
            <option value="P2111031700">P2111031700 Totalmix</option>
          </datalist>
        </div>
      </div>
    </ProjectLayout>
  );
};

export default observer(TimesheetPage);
