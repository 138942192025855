import { ReactNode } from 'react';

interface IProps {
  children?: ReactNode | ReactNode[];
}

const Table: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">{children}</table>
    </div>
  );
};

export default Table;
