import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { __NEW__ } from '../../../../constants';
import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import { useStore } from '../../../../stores/RootStore';
import HealthLayout from '../../components/Layout';
import Patient from '../../entities/Patient';
import patientService from '../../services/patientService';
import ListaAvaliacaoTma from './components/listaAvaliacaoTma';

const PatientsEditPage = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const { setMessage } = useStore('uiState');
  const [entity, setEntity] = useState<Patient>(new Patient('', ''));
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentAge, setCurrentAge] = useState(0);

  useEffect(() => {
    if (id === __NEW__ || entity.id) {
      return;
    }

    setLoading(true);
    patientService
      .getById(id)
      .then(value => setEntity(value ?? entity))
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }, [id, entity]);

  useEffect(() => setCurrentAge(patientService.getAge(entity.birth_date)), [entity.birth_date]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleError = (error: any) => {
    setMessage('error', typeof error === 'string' ? error : JSON.stringify(error));
  };
  const handleAdd = () => {
    patientService
      .add(entity)
      .then(() => {
        setMessage('success', 'Registro criado com sucesso');
        history.push('/health/patients');
      })
      .catch(handleError);
  };
  const handleUpdate = () => {
    patientService
      .update(id, entity)
      .then(() => {
        setMessage('success', 'Registro atualizado com sucesso');
        history.push('/health/patients');
      })
      .catch(handleError);
  };
  const handleSave = () => {
    setSaving(true);
    patientService
      .validator(entity, id === __NEW__ ? 'add' : 'update')
      .then(() => (id === __NEW__ ? handleAdd() : handleUpdate()))
      .catch(handleError)
      .finally(() => setSaving(false));
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/health' },
        { label: 'Pacientes', value: '/health/patients' },
        { label: id === __NEW__ ? 'Novo' : 'Editar', value: `/health/patients/${id}` },
      ]}
    />
  );

  const PageHeader = () => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h1>Paciente</h1>

      <div>
        {id !== __NEW__ ? (
          <div className="dropdown mb-2 me-2 text-end d-inline-block">
            <button
              className="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Adicionar
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <Link className="dropdown-item" to={`/health/patients/${id}/avaliacaoTma/new`}>
                  Avaliação TMA
                </Link>
              </li>
            </ul>
          </div>
        ) : undefined}
        <div className="d-inline-block btn-group" role="group">
          <Link to="/health/patients" className="btn btn-secondary">
            Cancelar
          </Link>
          <button disabled={saving} onClick={handleSave} className="btn btn-dark">
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader />
      <Loading active={loading} />

      <HideConditional condition={loading}>
        <div className="row">
          <div className="mb-3 col-5">
            <label htmlFor="name" className="form-label">
              Nome completo
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={entity.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="birth_date" className="form-label">
              Data de nascimento
            </label>
            <input
              type="date"
              className="form-control"
              id="birth_date"
              name="birth_date"
              value={entity.birth_date}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="currentAge" className="form-label">
              Idade
            </label>
            <input
              type="text"
              className="form-control"
              id="currentAge"
              name="currentAge"
              value={currentAge}
              readOnly
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="genre" className="form-label">
              Sexo
            </label>
            <select
              className="form-select"
              id="genre"
              name="genre"
              value={entity.genre}
              onChange={handleInputChange}>
              <option value="">-- Selecione --</option>
              <option value="F">Feminino</option>
              <option value="M">Masculino</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col">
            <label htmlFor="docto" className="form-label">
              CPF
            </label>
            <input
              type="text"
              className="form-control"
              id="docto"
              name="docto"
              placeholder="000.000.000-00"
              value={entity.docto}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="occupation" className="form-label">
              Profissão
            </label>
            <input
              type="text"
              className="form-control"
              id="occupation"
              name="occupation"
              value={entity.occupation}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              name="email"
              value={entity.email}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col">
            <label htmlFor="address" className="form-label">
              Endereço
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              name="address"
              value={entity.address}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 col">
            <label htmlFor="telephone" className="form-label">
              Telefone
            </label>
            <input
              type="tel"
              className="form-control"
              id="telephone"
              placeholder="(xx) 0000-0000"
              name="telephone"
              value={entity.telephone}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="py-2" />

        <HideConditional condition={id === __NEW__}>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              {/* <button
              className="nav-link"
              id="nav-atendimentos-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-atendimentos"
              type="button"
              role="tab"
              aria-controls="nav-atendimentos"
              aria-selected="false">
              Atendimentos
            </button> */}

              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true">
                Avaliações
              </button>
            </div>
          </nav>

          <div className="tab-content border border-top-0 p-3" id="nav-tabContent">
            {/* <div
            className="tab-pane fade"
            id="nav-atendimentos"
            role="tabpanel"
            aria-labelledby="nav-atendimentos-tab">
            ...
          </div> */}

            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab">
              <ListaAvaliacaoTma patientId={id} />
            </div>
          </div>
        </HideConditional>
      </HideConditional>
    </HealthLayout>
  );
};

export default PatientsEditPage;
