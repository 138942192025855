import AsyncStorage from '@react-native-async-storage/async-storage';

export class StorageService {
  loadFromStorage = async (key: string) => {
    try {
      const jsonValue = await AsyncStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {}
  };

  persistToStorage = async (key: string, content: any) => {
    try {
      await AsyncStorage.setItem(key, JSON.stringify(content));
    } catch (e) {}
  };
}
