import '../../assets/health.css';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { __NEW__ } from '../../../../constants';
import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import HealthLayout from '../../components/Layout';
import AvaliacaoTma, { TesteItem } from '../../entities/AvaliacaoTma';
import Patient from '../../entities/Patient';
import avaliacaoTmaService from '../../services/avaliacaoTmaService';
import patientService from '../../services/patientService';

// import men_body from '../../images/men-body.png';
// import woman_body from '../../images/woman-body.png';

export interface IIndexable {
  [key: string]: any;
}

const EditAvaliacaoTmaPage = () => {
  const { patientId, id } = useParams<any>();
  const history = useHistory();
  const { setMessage } = useStore('uiState');
  const [entity, setEntity] = useState<AvaliacaoTma>(new AvaliacaoTma('', '', patientId));
  const [patient, setPatient] = useState<Patient>();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (patient) {
      return;
    }

    setLoading(true);
    patientService
      .getById(patientId)
      .then(value => {
        setPatient(value);

        if (id === __NEW__ || entity?.id) {
          return;
        }

        avaliacaoTmaService
          .getById(id)
          .then(value => setEntity(value ?? new AvaliacaoTma('', '', patientId)))
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }, [entity?.id, id, patient, patientId]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleError = (error: any) => {
    setMessage('error', typeof error === 'string' ? error : JSON.stringify(error));
  };
  const handleAdd = () => {
    avaliacaoTmaService
      .add(entity)
      .then(() => {
        setMessage('success', 'Registro criado com sucesso');
        history.push(`/health/patients/${patientId}`);
      })
      .catch(handleError);
  };
  const handleUpdate = () => {
    avaliacaoTmaService
      .update(id, entity)
      .then(() => {
        setMessage('success', 'Registro atualizado com sucesso');
        history.push(`/health/patients/${patientId}`);
      })
      .catch(handleError);
  };
  const handleSave = () => {
    setSaving(true);
    avaliacaoTmaService
      .validator(entity, id === __NEW__ ? 'add' : 'update')
      .then(() => (id === __NEW__ ? handleAdd() : handleUpdate()))
      .catch(handleError)
      .finally(() => setSaving(false));
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/health' },
        { label: 'Pacientes', value: '/health/patients' },
        { label: patient?.name ?? '', value: `/health/patients/${patientId}` },
        { label: 'Avaliação', value: '' },
      ]}
    />
  );

  const renderAvaliacaoHeader = () => {
    return (
      <div className="card card-body bg-light mb-3">
        <div className="row">
          <div className="col-6">
            Paciente: <b>{patient?.name}</b>
          </div>
          <div className="col-4">
            Profissão: <b>{patient?.occupation}</b>
          </div>
          <div className="col-2 text-end">
            Idade: <b>{patientService.getAge(patient?.birth_date)}</b>
          </div>
        </div>
      </div>
    );
  };

  const renderTextArea = (field: string, label: string, className?: string) => (
    <div className={className}>
      <label htmlFor={field} className="form-label">
        {label}
      </label>
      <textarea
        className="form-control mb-3"
        id={field}
        name={field}
        rows={2}
        value={(entity as IIndexable)[field]}
        onChange={handleInputChange}
      />
    </div>
  );

  const renderInput = (field: string, label: string, className?: string) => (
    <div className={`row mb-3 ${className}`}>
      <label htmlFor={field} className="col-form-label col-sm-2 col-lg-1">
        {label}
      </label>
      <div className="col-sm-10 col-lg-11">
        <input
          className="form-control"
          id={field}
          name={field}
          value={(entity as IIndexable)[field]}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );

  const renderOption = (field: string, label: string) => (
    <div className="form-check form-check-inline col">
      <input
        className="form-check-input"
        type="checkbox"
        id={field}
        name={field}
        checked={(entity as IIndexable)[field]}
        onChange={handleInputChange}
      />
      <label className="form-check-label" htmlFor={field}>
        {label}
      </label>
    </div>
  );

  const renderMain = () => (
    <div className="row">
      <div className="col">
        <div className="card card-body bg-light mb-3">
          {renderTextArea('queixa_principal', 'Queixa principal')}
          {renderTextArea('quando_iniciou', 'Quando iniciou')}
          {renderTextArea('evolucao_da_dor', 'Evolução da dor')}
        </div>

        <div className="card card-body bg-light mb-3">
          <label htmlFor="evaRange" className="form-label">
            EVA
          </label>
          <input
            type="range"
            className="form-range"
            min="0"
            max="10"
            step="1"
            id="evaRange"
            name="evaRange"
            value={entity.evaRange}
            onChange={handleInputChange}
          />
          <div className="d-flex justify-content-between scale-gradient">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(it => (
              <div
                key={it}
                style={{ minWidth: 25 }}
                className={`text-center ${it === entity.evaRange ? 'fw-bold' : ''}`}>
                {it}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-around scale-gradient">
            {['Sem dor', 'Dor leve', 'Dor moderada', 'Dor intensa', 'Dor máxima'].map(it => (
              <div key={it} style={{ minWidth: 100 }} className="text-center px-1">
                {it}
              </div>
            ))}
          </div>
        </div>

        <div className="card card-body bg-light mb-3">
          {renderTextArea('impedimentos', 'O que impede de fazer')}
        </div>

        <div className="card card-body bg-light mb-3">
          <label className="form-label">Tipo de dor</label>
          {renderOption(
            'tipo_de_dor_1',
            'Queimação/Latência (ligamentar) "Não é causa, só está sendo sobrecarregado"',
          )}
          {renderOption(
            'tipo_de_dor_2',
            'Contração muscular/ Ponto gatilho com dor referida (Muscular)',
          )}
          {renderOption('tipo_de_dor_3', 'Neural paciente delimita a dor com dedo')}
          {renderOption('tipo_de_dor_4', 'Visceral dor constante')}
        </div>

        <div className="card card-body bg-light mb-3 flex-row">
          <div className="flex-fill">{renderTextArea('o_que_melhora', 'O que melhora?')}</div>
          <div className="p-2" />
          <div className="flex-fill">{renderTextArea('o_que_piora', 'O que piora?')}</div>
        </div>

        <div className="card card-body bg-light mb-3 flex-row">
          {renderOption('acorda_com_dor', 'Acorda com dor')}
          {renderOption('piora_durante_dia', 'Piora ao longo do dia')}
        </div>

        <div className="card card-body bg-light mb-3">
          <div className="row">
            <div className="col-md-6">
              {renderTextArea(
                'trauma_recente_relacionado_a_dor',
                'Trauma recente relacionado a dor',
              )}
              {renderTextArea('historico_de_cancer', 'Histórico de câncer')}
              {renderTextArea('perda_da_forca_abrupta', 'Perda de força abrupta - onde', 'mb-3')}
            </div>
            <div className="col-md-6 d-flex flex-column">
              {/* <div className="d-flex flex-column"> */}
              {renderOption('perda_de_peso_inexplicada', 'Perda de peso inexplicada')}
              {renderOption('febre_inexplicada', 'Febre inexplicada')}
              {renderOption('dor_de_madrugada', 'Dor de madrugada')}
              {renderOption('incontinencia_urinaria', 'Incontinência urinaria')}
              {renderOption('incontinencia_fecal', 'Incontinência fecal')}
              {renderOption('dormencia_em_sela', 'Dormência de sela')}
              {renderOption('pe_caido', 'Pé caído')}
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="card card-body bg-light mb-3">
          <div className="row">
            {renderTextArea('tratamentos_anteriores', 'Tratamentos anteriores', 'col-md-6')}
            {renderTextArea('medicacao', 'Medicação', 'col-md-6')}
            {renderTextArea('cirurgias', 'Cirurgias', 'col-md-6')}
            {renderTextArea('traumas', 'Traumas', 'col-md-6')}
            {renderTextArea('cicatrizes', 'Cicatrizes', 'col-md-6')}
            {renderTextArea('visceral', 'Visceral', 'col-md-6')}
            {renderTextArea('sono', 'Sono', 'col-md-6')}
            {renderTextArea('alimentacao', 'Alimentação', 'col-md-6')}
            {renderTextArea('atividade_fisica', 'Atividade física', 'col-md-6')}
            {renderTextArea('exame_de_image_achados', 'Exame de imagem - achados', 'col-md-6')}
          </div>
        </div>

        <div className="card card-body bg-light mb-3">
          <div className="row">
            {renderTextArea('inspecao_estatica', 'Inspeção estática', 'col-md-6')}
            {renderTextArea('inspecao_dinamica', 'Inspeção dinâmica', 'col-md-6')}
          </div>
          {renderTextArea('testes_especificos', 'Testes específicos')}
        </div>

        <div className="card card-body bg-light mb-3">
          <label className="form-label">Captores</label>
          {renderInput('captores_olhos', 'Olhos')}
          {renderInput('captores_atm', 'ATM')}
          {renderInput('captores_pe', 'Pés')}
        </div>
      </div>
    </div>
  );

  const handleLineChange = (event: any, item: TesteItem, tipoTeste: 'lombar' | 'cervical') => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let te: TesteItem[] = [];

    if (tipoTeste === 'lombar') {
      setEntity({
        ...entity,
        teste_lombar: [
          ...entity.teste_lombar.reduce((p, c) => {
            if (c.nome_teste === item.nome_teste) {
              p.push({ ...c, [name]: value });
            } else {
              p.push(c);
            }
            return p;
          }, te),
        ],
      });
    }

    if (tipoTeste === 'cervical') {
      setEntity({
        ...entity,
        teste_cervical: [
          ...entity.teste_cervical.reduce((p, c) => {
            if (c.nome_teste === item.nome_teste) {
              p.push({ ...c, [name]: value });
            } else {
              p.push(c);
            }
            return p;
          }, te),
        ],
      });
    }
  };

  // > TABLE ------------------------------------------------------------------

  const renderTextAreaTable = (
    field: string,
    item: TesteItem,
    tipoTeste: 'lombar' | 'cervical',
  ) => (
    <textarea
      className="form-control mb-3"
      id={field}
      name={field}
      rows={2}
      value={(item as IIndexable)[field]}
      onChange={e => handleLineChange(e, item, tipoTeste)}
    />
  );

  const renderOptionTable = (field: string, item: TesteItem, tipoTeste: 'lombar' | 'cervical') => (
    <input
      className="form-check-input"
      type="checkbox"
      id={field}
      name={field}
      checked={(item as IIndexable)[field]}
      onChange={e => handleLineChange(e, item, tipoTeste)}
    />
  );

  const renderTesteLombar = () => (
    <div>
      <div className="text-center fw-bold mb-3 py-1 text-uppercase bg-warning">
        Teste para Preferência Direcional LOMBAR
      </div>

      <div className="card card-body bg-light mb-3">
        {renderTextArea('teste_lombar_sintomas_pre_teste', 'Sintomas pré teste')}
      </div>

      <table className="table table-striped table-light">
        <thead className="table-dark">
          <tr>
            <th>Teste</th>
            <th>Sintomas durante teste</th>
            <th>Sintomas pós teste</th>
            <th>ADM +</th>
            <th>ADM -</th>
            <th>N.E.</th>
          </tr>
        </thead>
        <tbody>
          {entity.teste_lombar.map((t, tk) => (
            <tr key={tk}>
              <td>{t.nome_teste}</td>
              <td>{renderTextAreaTable('sintomas_durante_teste', t, 'lombar')}</td>
              <td>{renderTextAreaTable('sintomas_apos_teste', t, 'lombar')}</td>
              <td>{renderOptionTable('mais_adm', t, 'lombar')}</td>
              <td>{renderOptionTable('menos_adm', t, 'lombar')}</td>
              <td>{renderOptionTable('ne', t, 'lombar')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderTesteCervical = () => (
    <div>
      <div className="text-center fw-bold mb-3 py-1 text-uppercase bg-warning">
        Teste para Preferência Direcional CERVICAL
      </div>

      <div className="card card-body bg-light mb-3">
        {renderTextArea('teste_cervical_sintomas_pre_teste', 'Sintomas pré teste')}
      </div>

      <table className="table table-striped table-light">
        <thead className="table-dark">
          <tr>
            <th>Teste</th>
            <th>Sintomas durante teste</th>
            <th>Sintomas pós teste</th>
            <th>ADM +</th>
            <th>ADM -</th>
            <th>N.E.</th>
          </tr>
        </thead>
        <tbody>
          {entity.teste_cervical.map((t, tk) => (
            <tr key={tk}>
              <td>{t.nome_teste}</td>
              <td>{renderTextAreaTable('sintomas_durante_teste', t, 'cervical')}</td>
              <td>{renderTextAreaTable('sintomas_apos_teste', t, 'cervical')}</td>
              <td>{renderOptionTable('mais_adm', t, 'cervical')}</td>
              <td>{renderOptionTable('menos_adm', t, 'cervical')}</td>
              <td>{renderOptionTable('ne', t, 'cervical')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // < TABLE ------------------------------------------------------------------

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader
        title="Avaliação Terapia Mecânica Avançada (TMA)"
        actions={[
          {
            title: 'Imprimir',
            url: `/health/patients/${patientId}/avaliacaoTma/${id}/print`,
          },
          {
            title: 'Cancelar',
            url: `/health/patients/${patientId}`,
          },
          {
            title: 'Salvar',
            onClick: handleSave,
          },
        ]}
      />
      <Loading active={loading} />

      {renderAvaliacaoHeader()}

      <HideConditional condition={loading}>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="main-tab"
              data-bs-toggle="tab"
              data-bs-target="#main"
              type="button"
              role="tab"
              aria-controls="main"
              aria-selected="true">
              Principal
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="testes-tab"
              data-bs-toggle="tab"
              data-bs-target="#testes"
              type="button"
              role="tab"
              aria-controls="testes"
              aria-selected="false">
              Testes
            </button>
          </li>
        </ul>

        <div className="tab-content p-3 border rounded-bottom border-top-0" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="main"
            role="tabpanel"
            aria-labelledby="main-tab">
            {renderMain()}
          </div>
          <div className="tab-pane fade" id="testes" role="tabpanel" aria-labelledby="testes-tab">
            {renderTesteLombar()}
            <div className="py-3" />
            {renderTesteCervical()}
          </div>
        </div>
      </HideConditional>
    </HealthLayout>
  );
};

export default EditAvaliacaoTmaPage;
