import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { InputText } from '../../../../components/Form';
import { __NEW__ } from '../../../../constants';

import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Layout from '../../../../shared/Layout';
import Loading from '../../../../shared/Loading';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import User from '../../entities/User';
import { useMainStore } from '../../stores/RootStore';

const UserProfilePage = () => {
  const { setMessage } = useStore('uiState');
  const { user } = useStore('authState');
  const { loadind_current, current, saving, errors, getCurrent, add, update } =
    useMainStore('userState');
  const [entity, setEntity] = useState<User>(new User());

  useEffect(() => {
    if (entity.id) {
      return;
    }
    getCurrent(user?.email ?? '');
  }, [entity.id, getCurrent, user?.email]);

  useEffect(() => {
    if (current && !entity.id) {
      setEntity(current);
    }
  }, [current, entity.id]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setMessage('error', JSON.stringify(errors));
    }
  }, [errors, setMessage]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setEntity({
      ...entity,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (!entity.id) {
      return;
    }

    if (entity.id === __NEW__) {
      const result = await add(entity);
      if (result) {
        setMessage('success', result);
      }
      return;
    }

    const result = await update(entity);
    if (result) {
      setMessage('success', result);
    }
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/' },
        { label: 'Perfil', value: '' },
      ]}
    />
  );

  return (
    <Layout breadcrumb={renderPath}>
      <PageHeader title="Perfil do usuário" actions={[{ title: 'Salvar', onClick: handleSave }]} />
      <Loading active={loadind_current || saving} />

      <HideConditional condition={loadind_current || saving}>
        <div className="row">
          <InputText
            label="Nome completo"
            name="name"
            value={entity.name}
            className="col-lg-5"
            onChange={handleInputChange}
          />

          <InputText
            label="E-mail"
            name="email"
            type="email"
            placeholder="name@example.com"
            value={entity.email}
            className="col-lg"
            onChange={handleInputChange}
          />

          <InputText
            label="Telefone"
            name="telephone"
            type="tel"
            placeholder="(xx) 0000-0000"
            value={entity.telephone}
            className="col-lg"
            onChange={handleInputChange}
          />
        </div>
      </HideConditional>
    </Layout>
  );
};

export default observer(UserProfilePage);
