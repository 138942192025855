import { ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
}

const TableBody: React.FC<IProps> = ({ children }: IProps) => {
  return <tbody>{children}</tbody>;
};

export default TableBody;
