import Layout from '../../../shared/Layout';
import ProjectNavbar from './Navbar';

interface IProps {
  breadcrumb?: any;
  children?: any;
}

const ProjectLayout = ({ breadcrumb, children }: IProps) => (
  <Layout breadcrumb={breadcrumb} navbar={ProjectNavbar}>
    {children}
  </Layout>
);

export default ProjectLayout;
