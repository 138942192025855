import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { Table, TableBody, TableHeader, TableLine } from '../../../../components/Table';
import dateHelper from '../../../../helpers/dateHelper';
import Breadcrumb from '../../../../shared/Breadcrumb';
import HideConditional from '../../../../shared/HideConditional';
import Loading from '../../../../shared/Loading';
import PageHeader from '../../../../shared/PageHeader';
import { useStore } from '../../../../stores/RootStore';
import HealthLayout from '../../components/Layout';
import patientService from '../../services/patientService';
import { useHealthStore } from '../../stores/RootStore';

const PatientsPage = () => {
  const { setMessage } = useStore('uiState');
  const { loading_items, items, getItems, removeItem } = useHealthStore('patientState');

  useEffect(() => getItems(), [getItems]);

  const handleRemove = (id: string) => {
    removeItem(id).then(({ type, message }) => setMessage(type, message));
  };

  const renderPath = () => (
    <Breadcrumb
      items={[
        { label: 'Início', value: '/health' },
        { label: 'Pacientes', value: '/health/patients' },
      ]}
    />
  );

  return (
    <HealthLayout breadcrumb={renderPath}>
      <PageHeader
        title="Pacientes"
        actions={[
          {
            title: 'Novo',
            url: '/health/patients/new',
          },
        ]}
      />
      <Loading active={loading_items} />

      <HideConditional condition={loading_items}>
        <Table>
          <TableHeader
            classList={[null, null, 'text-center', 'text-end', null]}
            columns={['Nome', 'Profissão', 'Idade', 'Último atendimento']}
          />
          <TableBody>
            {items?.map((it, i) => (
              <TableLine
                key={i}
                id={it.id}
                linkTo={`/health/patients/${it.id}`}
                classList={[null, null, 'text-center', 'text-end', null]}
                values={[
                  it.name,
                  it.occupation,
                  patientService.getAge(it.birth_date),
                  `${dateHelper.format(new Date(it.createdAt), 'dd/MM/yyyy hh:mm:ss')}`,
                ]}
                // onRemovePress={handleRemove}
              />
            ))}
          </TableBody>
        </Table>
      </HideConditional>
    </HealthLayout>
  );
};

export default observer(PatientsPage);
