import { observer } from 'mobx-react';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import MainChangemanPage from '../modules/changeman/pages/main';

import CompaniesPage from '../modules/health/pages/companies';
import CompaniesEditPage from '../modules/health/pages/companies/edit';
import MainHealthPage from '../modules/health/pages/main';
import PatientsPage from '../modules/health/pages/patients';
import PatientsEditPage from '../modules/health/pages/patients/edit';
import EditAvaliacaoTmaPage from '../modules/health/pages/patients/editAvaliacaoTma';
import ReportAvaliacaoTma from '../modules/health/pages/reports/ReportAvaliacaoTma';
import TicketsPage from '../modules/helpdesk';
import UserProfilePage from '../modules/main/pages/profile';
import MainProjectPage from '../modules/project/pages/main';
import TimesheetPage from '../modules/project/pages/timesheet';
import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import CreateAccount from '../pages/Login/createAccount';
import firebase from '../settings/firebase-settings';
import { stores, StoresProvider, useStore } from '../stores/RootStore';

const AppRouter: React.FC = () => {
  return (
    <StoresProvider value={stores}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/">
            <HomePage />
          </PrivateRoute>

          <PrivateRoute exact path="/health">
            <MainHealthPage />
          </PrivateRoute>
          <PrivateRoute exact path="/health/patients">
            <PatientsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/health/patients/:id">
            <PatientsEditPage />
          </PrivateRoute>
          <PrivateRoute exact path="/health/patients/:patientId/avaliacaoTma/:id">
            <EditAvaliacaoTmaPage />
          </PrivateRoute>
          <PrivateRoute exact path="/health/patients/:patientId/avaliacaoTma/:id/print">
            <ReportAvaliacaoTma />
          </PrivateRoute>

          <PrivateRoute exact path="/health/companies">
            <CompaniesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/health/companies/:id">
            <CompaniesEditPage />
          </PrivateRoute>

          <PrivateRoute exact path="/helpdesk">
            <TicketsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/changeman">
            <MainChangemanPage />
          </PrivateRoute>

          <PrivateRoute exact path="/project">
            <MainProjectPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/timesheet">
            <TimesheetPage />
          </PrivateRoute>

          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/accounts/create">
            <CreateAccount />
          </Route>
          <PrivateRoute path="/profile">
            <UserProfilePage />
          </PrivateRoute>

          <PrivateRoute path="*">
            <div>404</div>
          </PrivateRoute>
        </Switch>
      </Router>
    </StoresProvider>
  );
};

const PrivateRouteBase = ({ children, ...rest }: any) => {
  const { authenticated } = useStore('authState');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated || firebase.auth.currentUser !== null ? (
          children
        ) : (
          <>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          </>
        )
      }
    />
  );
};

const PrivateRoute = observer(PrivateRouteBase);

export interface IStateType {
  from: { pathname: string };
}

export default AppRouter;
