import { makeAutoObservable } from 'mobx';

import { IResult } from '../../../interfaces/IResult';
import Company from '../entities/Company';
import companyService from '../services/companyService';

export class CompanyStore {
  storageKey = '@CompanyStore';

  current?: Company;
  loadind_current = false;

  items: Company[] = [];
  loading_items = false;

  errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getItemsByOwner = (ownerId: string) => {
    this.items = [];
    this.loading_items = true;
    this.errors = [];

    companyService.getByOwnerId(ownerId).then(this.setItemsSuccess).catch(this.setItemsFailure);
  };

  setItemsSuccess = (value: Company[]) => {
    this.items = value;
    this.loading_items = false;
  };

  setItemsFailure = (error: any) => {
    this.items = [];
    this.loading_items = false;
    this.errors = [JSON.stringify(error)];
    console.log(error);
  };

  removeItem = async (ownerId: string, id: string): Promise<IResult> => {
    return await companyService
      .remove(id)
      .then(() => {
        this.getItemsByOwner(ownerId);
        return { type: 'warning', message: 'Registro excluído com sucesso' } as IResult;
      })
      .catch(error => ({
        type: 'error',
        message: typeof error === 'string' ? error : JSON.stringify(error),
      }));
  };
}
