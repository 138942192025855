import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import firebase from '../../settings/firebase-settings';
import { useStore } from '../../stores/RootStore';

const TicketsPage: React.FC = () => {
  const { user } = useStore('authState');
  const [fromUser, setFromUser] = useState(user?.email);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const inputFile = useRef<HTMLInputElement>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (e.target.name === 'user') setFromUser(e.target.value);
    if (e.target.name === 'subject') setSubject(e.target.value);
    if (e.target.name === 'description') setDescription(e.target.value);
  };

  const handleCreateTicket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const new_uuid = uuidv4();
    const createdAt = Date.now();
    const date = new Date(createdAt);
    let ticketNum = `C${date.getFullYear().toString().substr(2, 2)}${date.getMonth()}`;
    ticketNum += `${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}`;

    setDoc(doc(firebase.firestore, 'tickets', new_uuid), {
      email: fromUser,
      subject,
      description,
      ticketNum,
      createdAt: createdAt,
    })
      .then(r => {
        uploadAttachment(new_uuid, e);
        loadTickets();
      })
      .catch(error => console.error(error));
  };

  const uploadAttachment = (uuid: string, e: React.FormEvent<HTMLFormElement>) => {
    const file = inputFile?.current?.files ? inputFile.current?.files[0] : undefined;

    if (!file) return;

    console.log('file', file);

    const destination = `/attachments/${uuid}/${file.name}`;
    const storageRef = ref(firebase.storage, destination);

    return firebase.uploadBytes(storageRef, file);
  };

  const [tickets, setTickets] = useState<any[]>();

  const loadTickets = async () => {
    const q = query(collection(firebase.firestore, 'tickets'));
    const result = await getDocs(q);
    const t: any[] = [];
    result.forEach(doc => {
      console.log(doc.id, '=>', doc.data());
      t.push({ id: doc.id, ...doc.data() });
    });
    setTickets(t);
  };
  useEffect(() => {
    loadTickets();
  }, []);

  return (
    <div className="container" style={{ flexDirection: 'column', padding: 15, flex: 1 }}>
      <h1>Chamados de suporte</h1>
      <Link to="/">Home</Link>

      <table className="table mb-3">
        <tbody>
          {tickets?.map(it => (
            <tr>
              <td>{it.ticketNum}</td>
              <td>{it.email}</td>
              <td>{it.subject}</td>
              <td>{it.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <form id="ticket_form" method="post" onSubmit={handleCreateTicket}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="user"
            value={fromUser}
            readOnly={true}
            onChange={handleChange}
          />
          <label htmlFor="user">Usuário</label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="subject"
            value={subject}
            onChange={handleChange}
            required
          />
          <label htmlFor="subject">Assunto</label>
        </div>

        <div className="form-floating mb-3">
          <textarea
            required
            className="form-control"
            placeholder="Descrição"
            name="description"
            value={description}
            onChange={handleChange}
            style={{ height: '200px' }}></textarea>
          <label htmlFor="description">Descrição</label>
        </div>

        <div className="mb-3">
          <input ref={inputFile} className="form-control" type="file" id="attachments" />
        </div>

        <div className="d-grid gap-2 mb-3">
          <button className="btn btn-secondary" type="submit">
            Enviar chamado
          </button>
        </div>
      </form>
    </div>
  );
};

export default TicketsPage;
