import Timesheet from '../entities/Timesheet';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import firebase from '../../../settings/firebase-settings';

const db = firebase.firestore;
const collection_name = 'project_timesheets';

const add = async (entity: Timesheet) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;

  try {
    await setDoc(doc(db, collection_name, new_uuid), {
      ...entity,
    });

    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const update = async (id: string, entity: Timesheet) => {
  const docRef = doc(db, collection_name, id);
  entity.updatedAt = Date.now();

  try {
    await updateDoc(docRef, { ...entity });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getByDate = (userId: string, date: number) => {
  return new Timesheet('');
};

const validator = (entity: Timesheet, action: 'add' | 'update') =>
  new Promise(async (resolve, reject) => {
    if (!entity.startHour) return reject('Preencha o hora de início');
    if (!entity.startMinute) return reject('Preencha o minuto de início');
    if (!entity.endHour) return reject('Preencha o hora de término');
    if (!entity.endMinute) return reject('Preencha o minuto de término');

    return resolve(true);
  });

const timesheetService = {
  add,
  update,
  getByDate,
  validator,
};

export default timesheetService;
