class Patient {
  id: string | undefined;
  name: string;
  genre: 'F' | 'M' | undefined;
  occupation: string;
  email: string;
  birth_date: string | undefined;
  address: string | undefined;
  telephone: string | undefined;
  docto: string | undefined;

  ownerId: string;
  companyId: string;

  createdAt!: number;
  updatedAt!: number;

  constructor(ownerId: string, companyId: string) {
    this.name = '';
    this.genre = undefined;
    this.occupation = '';
    this.email = '';
    this.birth_date = '';
    this.address = '';
    this.telephone = '';
    this.docto = '';

    this.ownerId = ownerId;
    this.companyId = companyId;
  }
}

export default Patient;
