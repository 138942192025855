import { Link } from 'react-router-dom';

const HealthNavbar = () => {
  return (
    <>
      <li className="nav-item">
        <Link className="nav-link active" aria-current="page" to="/health">
          Início
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" aria-current="page" to="/health/companies">
          Clínicas
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link active" aria-current="page" to="/health/patients">
          Pacientes
        </Link>
      </li>
    </>
  );
};

export default HealthNavbar;
