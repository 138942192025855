import { makeAutoObservable } from 'mobx';

import User from '../entities/User';
import userService from '../services/userService';

export class UserStore {
  storageKey = '@UserStore';

  current?: User;
  loadind_current = false;

  items: User[] = [];
  loading_items = false;

  saving = false;

  errors: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  // > GET current ------------------------------------------------------------
  getCurrent = (email: string) => {
    this.current = undefined;
    this.loadind_current = true;
    this.errors = [];
    userService.getCurrentUser(email).then(this.getCurrentSucces).catch(this.getCurrentFailure);
  };
  getCurrentSucces = (value: User) => {
    this.current = value;
    this.loadind_current = false;
    this.errors = [];
  };
  getCurrentFailure = (error: any) => {
    this.current = undefined;
    this.loadind_current = false;
    this.defaultFailure(error);
  };
  // < GET current ------------------------------------------------------------

  // > ADD/UPDATE user --------------------------------------------------------
  add = (value: User) => {
    this.errors = [];
    this.saving = true;
    return userService.add(value).then(this.saveSuccess).catch(this.saveFailure);
  };
  update = (value: User) => {
    if (!value.id) {
      this.errors = ['Invalid id'];
      return;
    }
    this.errors = [];
    this.saving = true;
    return userService.update(value.id, value).then(this.saveSuccess).catch(this.saveFailure);
  };
  saveSuccess = (value: boolean) => {
    this.saving = false;

    if (value !== true) {
      this.errors = ['Fail on add user'];
      return;
    }

    return 'Registro salvo com sucesso.';
  };
  saveFailure = (error: any) => {
    this.saving = false;
    this.defaultFailure(error);
  };
  // < ADD/UPDATE user --------------------------------------------------------

  defaultFailure = (error: any) => {
    this.errors = [JSON.stringify(error)];
    console.log(error);
  };
}
