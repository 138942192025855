import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import dateHelper from '../../../../../helpers/dateHelper';
import HideConditional from '../../../../../shared/HideConditional';
import Loading from '../../../../../shared/Loading';
import { useStore } from '../../../../../stores/RootStore';
import AvaliacaoTma from '../../../entities/AvaliacaoTma';
import avaliacaoTmaService from '../../../services/avaliacaoTmaService';

interface IProps {
  patientId: string;
}

const ListaAvaliacaoTma = ({ patientId }: IProps) => {
  const [items, setItems] = useState<AvaliacaoTma[]>([]);
  const { setMessage } = useStore('uiState');
  const [loading, setLoading] = useState(false);
  const [isMounted, setMounted] = useState(false);

  const loadFinished = (value: AvaliacaoTma[]) => {
    setItems(value);
    setLoading(false);
  };
  const loadError = (error: any) => {
    console.error(error);
    setLoading(false);
  };
  const load = useCallback(() => {
    setLoading(true);
    avaliacaoTmaService.getByPatient(patientId).then(loadFinished).catch(loadError);
  }, [patientId]);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted) {
      load();
    }
  }, [isMounted, load]);

  return (
    <>
      <Loading active={loading} />
      <HideConditional condition={loading}>
        <div className="text-end">
          <button className="btn btn-link" onClick={load}>
            Atualizar
          </button>
        </div>
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th>Tipo</th>
              <th className="text-center">EVA</th>
              <th className="text-end">Última avaliação</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((it) => {
              const handleRemove = () => {
                const r = window.confirm(
                  `Deseja excluir o registro '${it.title}'? \nEssa ação é irreversível.`,
                );

                if (!it.id || !r) {
                  return;
                }

                avaliacaoTmaService
                  .remove(it.id)
                  .then(() => {
                    setMessage('warning', 'Registro excluído com sucesso');
                    load();
                  })
                  .catch((error) => {
                    setMessage('error', typeof error === 'string' ? error : JSON.stringify(error));
                  });
              };

              return (
                <tr key={it.id}>
                  <td>
                    <Link
                      className="text-dark d-block"
                      to={`/health/patients/${patientId}/avaliacaoTma/${it.id}`}>
                      {it.title}
                    </Link>
                  </td>
                  <td className="text-center">
                    <Link
                      className="text-dark d-block"
                      to={`/health/patients/${patientId}/avaliacaoTma/${it.id}`}>
                      {it.evaRange}
                    </Link>
                  </td>
                  <td className="text-end">
                    <Link
                      className="text-dark d-block"
                      to={`/health/patients/${patientId}/avaliacaoTma/${it.id}`}>
                      {`${dateHelper.format(new Date(it.createdAt), 'dd/MM/yyyy hh:mm:ss')}`}
                    </Link>
                  </td>
                  <td className="p-0 pt-1 px-1 text-end">
                    <button className="btn btn-danger btn-sm" onClick={handleRemove}>
                      Excluir
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </HideConditional>
    </>
  );
};

export default ListaAvaliacaoTma;
