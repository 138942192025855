import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import jsonHelper from '../../../helpers/jsonHelper';

import firebase from '../../../settings/firebase-settings';
import AvaliacaoTma from '../entities/AvaliacaoTma';

const db = firebase.firestore;
const collection_name = 'health_patients_avaliacao_tma';

const add = async (entity: AvaliacaoTma) => {
  const new_uuid = uuidv4();
  const createdAt = Date.now();
  entity.id = new_uuid;
  entity.createdAt = createdAt;
  entity.updatedAt = createdAt;

  console.log(entity);

  try {
    await setDoc(doc(db, collection_name, new_uuid), {
      ...jsonHelper.castTo<any>(entity),
    });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const update = async (id: string, entity: AvaliacaoTma) => {
  const docRef = doc(db, collection_name, id);
  entity.updatedAt = Date.now();

  try {
    await updateDoc(docRef, { ...jsonHelper.castTo<any>(entity) });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const remove = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  try {
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getById = async (id: string) => {
  const docRef = doc(db, collection_name, id);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists) {
    return undefined;
  }
  return jsonHelper.castTo<AvaliacaoTma>({ ...snapshot.data(), id: snapshot.id });
};

const getByPatient = async (patientId: string) => {
  const q = query(
    collection(db, collection_name),
    where('patientId', '==', patientId),
    orderBy('createdAt', 'desc'),
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => jsonHelper.castTo<AvaliacaoTma>({ ...doc.data(), id: doc.id }));
};

const validator = (entity: AvaliacaoTma, action: 'add' | 'update') =>
  new Promise(async (resolve, reject) => {
    if (entity.queixa_principal === '' || entity.queixa_principal.length < 3)
      return reject('Preencha o campo queixa principal');

    return resolve(true);
  });

const avaliacaoTmaService = {
  add,
  remove,
  update,
  getByPatient,
  getById,
  // getByName,
  validator,
};

export default avaliacaoTmaService;
